import React from 'react'

const About = () => {
  return (
    <div name="about" className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
            <p className='text-xl mt-20'>
                A little about me. I'm a software engineer located in San Diego. I started my software engineering journey after working in an emergency department during the pandemic. I saw and worked through some of the hardest experiences anyone can imagine. It led me to realize that I enjoyed solving problems and building projects through engineering! I love the ability to create, critical think, and design something I'm proud of.


            </p>
            <br />
            <p className='text-xl'>

            Outside of software engineering, I enjoy surfing and staying active with boxing and running. I also love Formula 1 and motorsports in general and would love to be involved in that arena someday. I also have a dog named Gus who loves to go to the beach, play in the yard, and go camping on the weekends!

           </p>
        </div>
    </div>
  )
}

export default About
